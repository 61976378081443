import React from 'react'
import { navigate } from '@reach/router'
import { Container, Row, Col, FormGroup, Input } from 'reactstrap'
var slugify = require('slugify')

class JumpSection extends React.Component {
  render() {
    console.log(this.props.links)
    return (
      <Container>
        <div className="jump-section">
          <div className="d-none d-lg-block">
            <ul>
              <li>Click to jump to section:</li>
              {this.props.links.map((l, i) => (
                <li key={`l${i}`}>
                  <a href={`#${slugify(l, {
                    lower: true,
                    remove: /[*+~.()'"!:@?]/g,
                  })}`}>


                    {l.split('\n').map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-lg-none">
            <FormGroup>
              <Input
                aria-label="Jump to section"
                type="select"
                name="select"
                defaultValue={'title'}
                onChange={v => {
                  navigate(v.target.value)
                }}
              >
                <option value="title" disabled>
                  Jump to section...
                </option>
                {this.props.links.map((l, i) => (
                  <option
                    key={`o${i}`}
                    value={`#${slugify(l, {
                      lower: true,
                      remove: /[*+~.()'"!:@?]/g,
                    })}`}
                  >
                    {l.replace('\n', ' ')}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
        </div>
      </Container>
    )
  }
}

export default JumpSection
