import React from 'react'
import { graphql, Link } from 'gatsby'

import Img from 'gatsby-image'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Container, Row, Col, FormGroup, Input, Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import JumpSection from '../components/jump-section'

class AirPollutionIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      accordionItemsOn: [],
    }
    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  toggleAccordion(item) {
    let accordionItemsOn = this.state.accordionItemsOn

    if (accordionItemsOn.includes(item)) {
      const index = accordionItemsOn.indexOf(item)
      accordionItemsOn.splice(index, 1)
    } else {
      accordionItemsOn.push(item)
    }
    this.setState({ accordionItemsOn })
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const pageTitle = get(this, 'props.data.contentfulAirPollution.title')
    const whatIsAirPollution = get(
      this,
      'props.data.contentfulAirPollution.whatIsAirPollution'
    )
    const mythbusting = get(
      this,
      'props.data.contentfulAirPollution.mythbusting'
    )

    const yourHealth = get(this, 'props.data.contentfulAirPollution.yourHealth')

    const whosAtRisk = get(this, 'props.data.contentfulAirPollution.whosAtRisk')

    const whatAreTheEffects = get(
      this,
      'props.data.contentfulAirPollution.whatAreTheEffects'
    )

    const stats = get(this, 'props.data.contentfulAirPollution.stats')

    const directorMessage = get(
      this,
      'props.data.contentfulAirPollution.directorMessage'
    )

    const accordianIntro = get(
      this,
      'props.data.contentfulAirPollution.accordianIntro'
    )

    const header = get(this, 'props.data.contentfulAirPollution.header')
    const headerMobile = get(
      this,
      'props.data.contentfulAirPollution.headerMobile'
    )

    const metaDescription = get(
      this,
      'props.data.contentfulAirPollution.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulAirPollution.metaImage.file.url'
    )

    const leftAccordian = get(
      this,
      'props.data.contentfulAirPollution.leftAccordian'
    )

    const rightAccordian = get(
      this,
      'props.data.contentfulAirPollution.rightAccordian'
    )


    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={pageTitle + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={'https://cleanairgm.com/air-pollution'}
            />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={pageTitle + ' | ' + siteTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>          <div className="page-banner">
            <Img
              fluid={header.fluid}
              className="page-banner__img d-none d-lg-block"
            />
            <Img
              fluid={headerMobile.fluid}
              className="page-banner__img d-block d-lg-none"
            />
            <h1>Air pollution and you</h1>
          </div>

          <JumpSection
            links={[
              'What is air pollution',
              'Mythbusting',
              'Your health',
              "Who's at risk",
              'What are the effects?',
            ]}
          />

          <Container
            id="what-is-air-pollution"
            className="mb-5 text-center text-lg-left"
          >
            <Row>
              <Col
                className="mt-4 mt-md-0 vc"
                xs={{ order: 1 }}
                sm={12}
                lg={{ size: 6, order: 0 }}
              >
                <Img className="br-10" fluid={whatIsAirPollution.image.fluid} />
              </Col>
              <Col className="vc" sm={12} lg={{ size: 6 }}>
                <div className="video__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: whatIsAirPollution.text.childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container
            className="video pt-5 pb-5 text-center text-lg-left"
          >
            <Row>
              {/* <Col
                className="mb-4 mt-4 mt-lg-0 mb-md-0 vc"
                xs={{ size: 12, order: 2 }}
                lg={{ size: 6 }}
              >
                <div className="re1t-player-wrapper">
                  <ReactPlayer
                    width="100%"
                    className="react-player"
                    height="100%"
                    controls
                    url={whatAreTheEffects.videoUrl}
                  />
                </div>
              </Col> */}

              <Col className="vc text-center" xs={12} lg={{ size: 8, offset: 2 }}>
                <div className="video__text">
                  <h1>{accordianIntro.title}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: accordianIntro.text.childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          </Container>


          <Container className='pb-5'>

            <Row>
              <Col xs={12} md={{ size: 6 }}>
                {leftAccordian.map((m, i) => (
                  <div key={`la${i}`} className="mythbusting__item mythbusting__item--grey">
                    <button
                      className={`mythbusting__button ${this.state.accordionItemsOn.includes('togglerla' + i)
                        ? 'on'
                        : 'off'
                        }`}
                      onClick={() => {
                        this.toggleAccordion(`togglerla${i}`)
                      }}
                    >
                      <span dangerouslySetInnerHTML={{
                        __html: m.title,
                      }} />{' '}
                      <FontAwesomeIcon
                        className={`chevron  ${this.state.accordionItemsOn.includes('togglerla' + i)
                          ? 'on'
                          : 'off'
                          }`}
                        icon={faChevronDown}
                      />
                    </button>
                    <Collapse
                      className="p-4 p-lg-5"
                      isOpen={this.state.accordionItemsOn.includes('togglerla' + i)}
                    >
                      <Row>
                        <Col sm={12}>
                          <div
                            className="mythbusting__text"
                            dangerouslySetInnerHTML={{
                              __html: m.text.childMarkdownRemark.html,
                            }}
                          ></div>
                        </Col>

                      </Row>
                    </Collapse>
                  </div>
                ))}




              </Col>
              <Col xs={12} md={{ size: 6 }}>
                {rightAccordian.map((m, i) => (
                  <div key={`ra${i}`} className="mythbusting__item mythbusting__item--grey">
                    <button
                      className={`mythbusting__button ${this.state.accordionItemsOn.includes('togglerra' + i)
                        ? 'on'
                        : 'off'
                        }`}
                      onClick={() => {
                        this.toggleAccordion(`togglerra${i}`)
                      }}
                    >
                       <span dangerouslySetInnerHTML={{
                        __html: m.title,
                      }} />{' '}
                      <FontAwesomeIcon
                        className={`chevron  ${this.state.accordionItemsOn.includes('togglerra' + i)
                          ? 'on'
                          : 'off'
                          }`}
                        icon={faChevronDown}
                      />
                    </button>
                    <Collapse
                      className="p-4 p-lg-5"
                      isOpen={this.state.accordionItemsOn.includes('togglerra' + i)}
                    >
                      <Row>
                        <Col sm={12}>
                          <div
                            className="mythbusting__text"
                            dangerouslySetInnerHTML={{
                              __html: m.text.childMarkdownRemark.html,
                            }}
                          ></div>
                        </Col>

                      </Row>
                    </Collapse>
                  </div>
                ))}
              </Col>
            </Row>


          </Container>

          <div id="mythbusting" className="mythbusting">
            <Container>
              <h2 className="mb-4">Mythbusting</h2>

              {mythbusting.items.map((m, i) => (
                <div key={`myth${i}`} className="mythbusting__item">
                  <button
                    className={`mythbusting__button ${this.state.accordionItemsOn.includes('toggler' + i)
                      ? 'on'
                      : 'off'
                      }`}
                    onClick={() => {
                      this.toggleAccordion(`toggler${i}`)
                    }}
                  >
                    {m.title}{' '}
                    <FontAwesomeIcon
                      className={`chevron  ${this.state.accordionItemsOn.includes('toggler' + i)
                        ? 'on'
                        : 'off'
                        }`}
                      icon={faChevronDown}
                    />
                  </button>
                  <Collapse
                    className="p-4 p-lg-5"
                    isOpen={this.state.accordionItemsOn.includes('toggler' + i)}
                  >
                    <Row>
                      <Col sm={12} lg={6}>
                        <div
                          className="mythbusting__text"
                          dangerouslySetInnerHTML={{
                            __html: m.text.childMarkdownRemark.html,
                          }}
                        ></div>
                      </Col>
                      <Col sm={12} lg={6}>
                        <Img
                          className="mythbusting__img"
                          fluid={m.image.fluid}
                        />
                      </Col>
                    </Row>
                  </Collapse>
                </div>
              ))}
            </Container>
          </div>

          <Container
            id="your-health"
            className="video pt-5 pb-5 text-center text-lg-left"
          >
            <Row>
              <Col xs={12} lg={6} className="vc">
                <div className="video__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: yourHealth.text.childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
              </Col>
              <Col className="mb-4 mb-md-0 vc" sm={12} lg={{ size: 6 }}>
                <div className="react-player-wrapper">
                  <ReactPlayer
                    width="100%"
                    className="react-player"
                    height="100%"
                    controls
                    url={yourHealth.videoUrl}
                  />
                </div>
              </Col>
            </Row>
          </Container>

          <div id="whos-at-risk" className="whos-at-risk">
            <Container>
              <h2>Who's at risk?</h2>
              <p>
                Air pollution affects everyone’s health, from birth to death.
              </p>

              <Row>
                {whosAtRisk.map((w, i) => (
                  <Col key={`war${i}`} sm={12} lg={4}>
                    <div className="whos-at-risk__section">
                      <Img
                        objectFit="contain"
                        className="whos-at-risk__section__image"
                        fluid={w.image.fluid}
                        imgStyle={{
                          objectFit: 'contain',
                          objectPosition: '50% 50%',
                        }}
                      />
                      <h3>{w.title}</h3>
                      <div
                        className="w-100"
                        dangerouslySetInnerHTML={{
                          __html: w.text.childMarkdownRemark.html,
                        }}
                      ></div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div className="what-can-you-do-banner">
            <h2>What can you do?</h2>
            <Link to="/be-the-change" className="btn btn-white ml-0 ml-lg-4">
              Changes you can make
            </Link>
          </div>

          <Container
            id="what-are-the-effects"
            className="video pt-5 pb-5 text-center text-lg-left"
          >
            <Row>
              {/* <Col
                className="mb-4 mt-4 mt-lg-0 mb-md-0 vc"
                xs={{ size: 12, order: 2 }}
                lg={{ size: 6 }}
              >
                <div className="react-player-wrapper">
                  <ReactPlayer
                    width="100%"
                    className="react-player"
                    height="100%"
                    controls
                    url={whatAreTheEffects.videoUrl}
                  />
                </div>
              </Col> */}

              <Col className="vc text-center" xs={12} lg={{ size: 8, offset: 2 }}>
                <div className="video__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: whatAreTheEffects.text.childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mb-5">
            <Row>
              {stats.map((s, i) => (
                <Col key={`st${i}`} sm={12} lg={3}>
                  <div className={`stat ${i % 2 ? 'stat--odd' : 'stat--even'}`}>
                    <h3>{s.title}</h3>
                    <div
                      className="d-inline-block d-lg-none"
                      dangerouslySetInnerHTML={{
                        __html: s.text.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="mt-3 d-none d-lg-flex">
              {stats.map((s, i) => (
                <Col key={`stt${i}`} sm={12} lg={3}>
                  <div className="stat">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: s.text.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>

          <div className="director-box pt-5 pb-5">
            <Container className="text-center text-lg-left">
              <Row>
                <Col xs={12} md={{ size: 6 }} className="vc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: directorMessage.text.childMarkdownRemark.html,
                    }}
                  ></div>
                </Col>
                <Col className="mt-4 mt-lg-0" xs={12} md={{ size: 6 }}>
                  <Img className="br-10" fluid={directorMessage.image.fluid} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AirPollutionIndex

export const pageQuery = graphql`
  query AirPollutionNewQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAirPollution {
      id
      title
      metaDescription
      metaImage {
        file {
          url
        }
      }
      header {
        fluid(maxWidth: 1920, maxHeight: 450, quality: 95) {
          ...GatsbyContentfulFluid
        }
      }
      headerMobile {
        fluid(maxWidth: 1080, maxHeight: 720) {
          ...GatsbyContentfulFluid
        }
      }
      whatIsAirPollution {
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 480) {
            ...GatsbyContentfulFluid
          }
        }
      }
      mythbusting {
        title
        items {
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 575, maxHeight: 360) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      yourHealth {
        videoUrl
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      whosAtRisk {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
      whatAreTheEffects {
        videoUrl
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      stats {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      directorMessage {
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
      accordianIntro {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      leftAccordian {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      rightAccordian {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
